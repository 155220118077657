import React from 'react'

import style from './floorplan.module.css'
import schlafzimmer1 from './schlafzimmer.jpg'
import schlafzimmer2 from './schlafzimmer-mitte.jpg'
import schlafzimmer3 from './schlafzimmer-nord.jpg'

const FloorPlanTop = ({ onRoomClick }) => {
  return (
    <svg className={style.floorPlan} width="380px" height="600px" viewBox="0 0 380 600" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="grayBlur">
          <feColorMatrix
            type="saturate"
            type="matrix"
            values="0 1 0 0 0
              0 1 0 0 0
              0 1 0 0 0
              0 1 0 0.5 0 "
          />
          <feGaussianBlur stdDeviation="1.2" />
        </filter>
        <filter id="grayscale">
          <feColorMatrix type="saturate" values="0.1" />
        </filter>
        <clipPath id="schlafzimmer1-clip">
          <rect x="0" y="0" width="220" height="180" />
        </clipPath>
        <clipPath id="schlafzimmer2-clip">
          <rect x="0" y="0" width="220" height="126" />
        </clipPath>
        <clipPath id="schlafzimmer3-clip">
          <rect x="0" y="0" width="160" height="131" />
        </clipPath>
      </defs>
      <g id="Grundriss-oben" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dusche" transform="translate(147.000000, 36.000000)">
          <rect id="Dusche-bg" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" width="51" height="74" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="14" fontWeight="normal" fill="#37393B">
            <tspan x="2" y="42">
              Dusche
            </tspan>
          </text>
        </g>
        <g id="Flur" transform="translate(17.000000, 116.000000)">
          <polygon
            id="FlurOG-bg"
            stroke="#979797"
            fill="#FFFFFF"
            points="0.0564339423 0.0580151561 181.989419 0.0580151561 182 51 115.557285 50.457984 115.557285 238.830981 43.1580037 238.830981 43.1580037 89.1527379 0.0564339423 89.1527379"
          />
          <text id="Flur-OG" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="33" y="40">
              Flur OG
            </tspan>
          </text>
        </g>
        <g
          id="Schlafzimmer1"
          clipPath="url(#schlafzimmer1-clip)"
          transform="translate(144.000000, 313.000000)"
          className={style.room}
          onClick={() => onRoomClick('sleepingRoom1')}
        >
          <rect id="Schlafzimmer1-bg" stroke="#979797" x="0.5" y="0.5" width="219" height="179" />
          <image xlinkHref={schlafzimmer1} width="220" height="180" preserveAspectRatio="none" />
          <text id="Schlafzimmer" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="55" y="91">
              Schlafzimmer
            </tspan>
          </text>
        </g>
        <g id="Bad" transform="translate(66.000000, 36.000000)">
          <rect id="Bad-bg" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" width="70" height="74" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="16" y="42">
              Bad
            </tspan>
          </text>
        </g>
        <g
          id="Schlafzimmer2"
          clipPath="url(#schlafzimmer2-clip)"
          transform="translate(144.000000, 177.000000)"
          className={style.room}
          onClick={() => onRoomClick('sleepingRoom2')}
        >
          <rect id="Schlafzimmer2-bg" stroke="#979797" x="0.5" y="0.5" width="219" height="125" />
          <image xlinkHref={schlafzimmer2} width="220" height="126" preserveAspectRatio="none" />
          <text id="Schlafzimmer" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="46" y="67">
              Schlafzimmer
            </tspan>
          </text>
        </g>
        <g id="Treppe" transform="translate(17.000000, 200.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-13" x="0.5" y="0.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="9.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="18.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="27.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="36.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="45.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="54.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="63.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="72.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="81.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="90.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="99.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="108.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="117.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="126.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="135.5" width="38" height="9" />
          <rect id="Rectangle-13" x="0.5" y="144.5" width="38" height="9" />
        </g>
        <g id="WC" transform="translate(17.000000, 36.000000)">
          <rect id="WC-bg" stroke="#979797" fill="#FFFFFF" x="0.5" y="0.5" width="43" height="74" />
          <text fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="5" y="45">
              WC
            </tspan>
          </text>
        </g>
        <g
          id="Schlafzimmer3"
          clipPath="url(#schlafzimmer3-clip"
          transform="translate(204.000000, 36.000000)"
          className={style.room}
          onClick={() => onRoomClick('sleepingRoom3')}
        >
          <rect id="Schlafzimmer3-bg" stroke="#979797" x="0.5" y="0.5" width="159" height="130" />
          <image xlinkHref={schlafzimmer3} width="160" height="131" preserveAspectRatio="none" />
          <text id="Schlafzimmer" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="20" fontWeight="normal" fill="#37393B">
            <tspan x="16" y="72">
              Schlafzimmer
            </tspan>
          </text>
        </g>
        <text id="Obergeschoss" fontFamily="AvenirNext-Regular, Avenir Next" fontSize="48" fontWeight="normal" fill="#4A4A4A">
          <tspan x="0" y="581">
            Obergeschoss
          </tspan>
        </text>
        <path
          d="M133.5,167.5 L133.5,354.5 L60.5,354.5 L60.5,200.5 L56.5,200.5 L56.5,354.5 L16.5,354.5 L16.5,115.5 L199.5,115.5 L199.5,167.5 L133.5,167.5 Z M143.5,303.5 L143.5,176.5 L364.5,176.5 L364.5,303.5 L143.5,303.5 Z M143.5,493.5 L143.5,312.5 L364.5,312.5 L364.5,493.5 L143.5,493.5 Z M133.5,358.5 L133.5,493.5 L60.5,493.5 L60.5,358.5 L133.5,358.5 Z M364.5,35.5 L364.5,167.5 L203.5,167.5 L203.5,35.5 L364.5,35.5 Z M146.5,35.5 L199.5,35.5 L199.5,111.5 L146.5,111.5 L146.5,35.5 Z M137.5,111.5 L65.5,111.5 L65.5,35.5 L137.5,35.5 L137.5,111.5 Z M61.5,35.5 L61.5,111.5 L16.5,111.5 L16.5,35.5 L61.5,35.5 Z M0.5,21.5 L0.5,507.5 L379.5,507.5 L379.5,21.5 L0.5,21.5 Z"
          id="Combined-Shape"
          stroke="#979797"
          fill="#D8D8D8"
        />
        <g id="Fenster" transform="translate(364.000000, 325.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="54" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="8" height="54" />
        </g>
        <g id="Fenster" transform="translate(364.000000, 408.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="54" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="8" height="54" />
        </g>
        <g id="Fenster" transform="translate(364.000000, 190.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="83" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="8" height="83" />
        </g>
        <g id="Fenster" transform="translate(364.000000, 74.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-14" x="0.5" y="0.5" width="8" height="52" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="8" height="52" />
        </g>
        <g
          id="Tür"
          transform="translate(154.000000, 228.500000) rotate(90.000000) translate(-154.000000, -228.500000) translate(138.500000, 208.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="12" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(154.000000, 330.500000) rotate(90.000000) translate(-154.000000, -330.500000) translate(138.500000, 310.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="12" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g id="Tür" transform="translate(24.000000, 83.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="4" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g id="Tür" transform="translate(85.000000, 83.000000)" fill="#FFFFFF" stroke="#000000">
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="4" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(108.500000, 370.500000) rotate(180.000000) translate(-108.500000, -370.500000) translate(93.000000, 354.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="4" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(176.500000, 99.500000) scale(-1, 1) translate(-176.500000, -99.500000) translate(161.000000, 83.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="4" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Tür"
          transform="translate(215.500000, 141.500000) scale(-1, 1) rotate(270.000000) translate(-215.500000, -141.500000) translate(200.000000, 125.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-12" x="1" y="28.5" width="29" height="4" />
          <path
            d="M29.9913793,28.5 L1,28.5 L1,0.539492377 C1.48811543,0.513274483 1.9796032,0.5 2.47413793,0.5 C17.6714907,0.5 29.9913793,13.036027 29.9913793,28.5 Z"
            id="Combined-Shape"
          />
        </g>
        <g
          id="Fenster"
          transform="translate(34.000000, 28.500000) rotate(90.000000) translate(-34.000000, -28.500000) translate(26.500000, 12.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="31" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="31" />
        </g>
        <g
          id="Fenster"
          transform="translate(85.000000, 28.500000) rotate(90.000000) translate(-85.000000, -28.500000) translate(77.500000, 12.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="31" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="31" />
        </g>
        <g
          id="Fenster"
          transform="translate(303.500000, 500.500000) rotate(90.000000) translate(-303.500000, -500.500000) translate(296.000000, 470.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="60" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="60" />
        </g>
        <g
          id="Fenster"
          transform="translate(199.500000, 500.500000) rotate(90.000000) translate(-199.500000, -500.500000) translate(192.000000, 470.000000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="60" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="60" />
        </g>
        <g
          id="Fenster"
          transform="translate(103.000000, 500.500000) rotate(90.000000) translate(-103.000000, -500.500000) translate(95.500000, 476.500000)"
          fill="#FFFFFF"
          stroke="#000000"
        >
          <rect id="Rectangle-14" x="0.5" y="0.5" width="7" height="47" />
          <rect id="Rectangle-15" x="7.5" y="0.5" width="7" height="47" />
        </g>
      </g>
    </svg>
  )
}

export default FloorPlanTop
